export const messageTemplate = {

"default": "",

"khitan-abduh": `Assalamu'alaikum Warahmatullahi Wabarokatuh.

Izinkan kami mengundang Bapak/Ibu/Saudara/i {{{guest_name}}} untuk dapat menghadiri acara Walimatul Khitan putra kami yang akan dilaksanakan pada Hari Ahad, 4 September 2022.

Besar harapan kami agar Anda dapat hadir memberikan doa untuk putra kami. Tiada yang dapat kami ungkapkan selain rasa terima kasih yang tulus.

Untuk Informasi Detail Acara, Lokasi, dan Waktu Lebih Lengkap silahkan akses link undangan berikut :
Link acara: https://elinvi.brawn.id/khitan-abduh/?mengundang={{{encoded_guest_name}}}


Kami yang berbahagia,
Bpk. H. M. Ammar Fasyni Elfauzi & Ibu Hj. Ghina Fauziyah`,

"winda-anggy": `Assalamu'alaikum Warahmatullahi Wabarokatuh.

Izinkan kami mengundang Bapak/Ibu/Saudara/i {{{guest_name}}} untuk dapat menghadiri acara Resepsi Pernikahan putra-putri kami yang akan dilaksanakan pada Hari Minggu, 25 September 2022.
Besar harapan kami agar Anda dapat hadir dan memberikan doa restu kepada  putra-putri kami. Tiada yang dapat kami ungkapkan selain rasa terima kasih yang tulus.

Untuk Informasi Detail Acara, Lokasi, dan Waktu Lebih Lengkap silahkan akses link undangan berikut :
Link acara: https://elinvi.brawn.id/winda-anggy/?mengundang={{{encoded_guest_name}}}


Kami yang berbahagia,
Keluarga besar

Winda & Anggi`,
"bernandy-angela": `Izinkan kami mengundang Bapak/Ibu/Saudara/i {{{guest_name}}} untuk dapat menghadiri acara Sakramen Perkawinan kami yang akan dilaksanakan pada Hari Sabtu, 1 Oktober 2022.

Besar harapan kami agar Anda dapat hadir memberikan doa dan restu di hari bahagia kami. Tiada yang dapat kami ungkapkan selain rasa terima kasih yang tulus.

Untuk Informasi Detail Acara, Lokasi, dan Waktu Lebih Lengkap silahkan akses link undangan berikut :

Link acara: https://elinvi.brawn.id/bernandy-angela/?mengundang={{{encoded_guest_name}}}

Link live streaming youtube : https://bit.ly/BernandyAngela

Kami yang berbahagia,
Angela & Bernandy`,
  
"idik-anis": `Assalamu'alaikum Warahmatullahi Wabarokatuh.

Izinkan kami mengundang Bapak/Ibu/Saudara/i :
  
{{{guest_name}}}

Untuk dapat menghadiri acara Resepsi Pernikahan putra-putri kami yang akan dilaksanakan pada Hari Minggu, 13 November 2022.
Besar harapan kami agar Anda dapat hadir dan memberikan doa restu kepada  putra-putri kami. Tiada yang dapat kami ungkapkan selain rasa terima kasih yang tulus.

Untuk Informasi Detail Acara, Lokasi, dan Waktu Lebih Lengkap silahkan akses link undangan berikut :
Link acara: https://elinvi.brawn.id/idik-anis/?mengundang={{{encoded_guest_name}}}

Kami yang berbahagia,
Keluarga besar

Anis & Idik

Wassalamu'alaikum warahmatullahi wabarokatuh`,
  "undangan-ulang-tahun-subky": `Assalamu'alaikum Warahmatullahi Wabarokatuh.

Izinkan kami mengundang Bapak/Ibu/Saudara/i :
  
{{{guest_name}}}

Untuk dapat menghadiri acara Milad putra kami yang ke-2 tahun, yang akan dilaksanakan pada Hari Minggu, 23 Oktober 2022.
Besar harapan kami agar Anda dapat hadir dan memberikan doa kepada putra kami. Tiada yang dapat kami ungkapkan selain rasa terima kasih yang tulus.

Untuk Informasi Detail Acara, Lokasi, dan Waktu Lebih Lengkap silahkan akses link undangan berikut :
Link acara: https://elinvi.brawn.id/undangan-ulang-tahun-subky/?mengundang={{{encoded_guest_name}}}

Kami yang berbahagia,
Keluarga besar

Bpk. Saiful Mahli & Ibu Rahmita Ulfa`,
  
  "third-anniversary-gkinvest": `Izinkan kami mengundang Bapak/Ibu/Saudara/i :
  
{{{guest_name}}}

Untuk dapat menghadiri acara Anniversary GKInvest Pontianak yang ke-3, yang akan dilaksanakan pada Hari Jumat, 11 November 2022.

Untuk Informasi Detail Acara, Lokasi, dan Waktu Lebih Lengkap silahkan akses link undangan berikut :
Link acara: https://elinvi.brawn.id/third-anniversary-gkinvest/?mengundang={{{encoded_guest_name}}}

Hormat Kami,
GKInvest`,
  "undangan-ulang-tahun-jasmine": `Assalamu'alaikum Wr. Wb. 

Alhamdulillah sudah tiba saatnya Ulang Tahun Jasmine.. 

Undangan ini spesial teruntuk:
  
{{{guest_name}}}

Besar harapan kami untuk bisa menghadiri Ulang Tahun Jasmine yang ke-4, yang akan dilaksanakan pada Hari Minggu, 6 November 2022.

Untuk Informasi Detail Acara, Lokasi, dan Waktu Lebih Lengkap silahkan akses link undangan berikut :
Link acara: https://elinvi.brawn.id/undangan-ulang-tahun-jasmine/?mengundang={{{encoded_guest_name}}}

Semoga kita semua dalam keadaan sehat dan bahagia selalu aamiin YRA 

Sampai Jumpa.. 😍 
 
 Wassalam
- Jasmine-

*CATATAN : 
Mohon dilengkapi Form Reservasi di Undangan sebagai tiket masuk wahana dan membawa Kaos Kaki ya.. 🙏🙏🤗🤗`,
  "aqiqah-maiza": `Assalamu'alaikum Warahmatullahi Wabarokatuh.

Tanpa mengurangi rasa hormat, Izinkan kami mengundang Bapak/Ibu/Saudara/i {{{guest_name}}}

Untuk dapat menghadiri acara "Tasyakuran Gunting Rambut & Aqiqah" putri pertama kami, yang akan dilaksanakan pada: 

Hari: Minggu, 13 November 2022
Pukul: 12.00 - 17.00 WIB
Alamat: Jl.Karet Gg.Karet Lestari No.16A (Pontianak)

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan do'a untuk putri kami. Tiada yang dapat kami ungkapkan selain rasa terima kasih yang tulus.

Untuk Informasi Detail Acara, Lokasi, dan Waktu Lebih Lengkap silahkan akses link undangan berikut :
Link acara: https://elinvi.brawn.id/aqiqah-maiza/?mengundang={{{encoded_guest_name}}}

Mohon maaf perihal undangan hanya di bagikan melalui pesan ini. 

Kami yang berbahagia,
Keluarga besar

Bpk. Raditya Putratama Subagyo, A.P & Ibu Lifia Noviani, S.Tr.Keb`,
  "kidung-bebella": `Izinkan kami mengundang Bapak/Ibu/Saudara/i {{{guest_name}}} untuk dapat menghadiri acara Pemberkatan Pernikahan kami yang akan dilaksanakan pada Hari Sabtu, 19 November 2022.

Besar harapan kami agar Anda dapat hadir memberikan doa dan restu di hari bahagia kami. Tiada yang dapat kami ungkapkan selain rasa terima kasih yang tulus.

Untuk Informasi Detail Acara, Lokasi, dan Waktu Lebih Lengkap silahkan akses link undangan berikut :
Link acara: https://elinvi.brawn.id/kidung-bebella/?mengundang={{{encoded_guest_name}}}

Kami yang berbahagia,
Bebella & Kidung`,
  "syaid-nichelle": `Assalamu'alaikum Warahmatullahi Wabarokatuh.

Izinkan kami mengundang Bapak/Ibu/Saudara/i {{{guest_name}}} untuk dapat menghadiri acara Resepsi Pernikahan kami yang akan dilaksanakan pada hari Sabtu, 26 November 2022.

Besar harapan kami agar Anda dapat hadir dan memberikan doa restu kepada kami. Tiada yang dapat kami ungkapkan selain rasa terima kasih yang tulus.

Untuk Informasi Detail Acara, Lokasi, dan Waktu Lebih Lengkap silahkan akses link undangan berikut :
Link acara: https://elinvi.brawn.id/syaid-nichelle/?mengundang={{{encoded_guest_name}}}

Kami yang berbahagia,

Nichelle & Syaid`,
  "syahid-faradila": `Assalamu'alaikum Warahmatullahi Wabarokatuh.

Izinkan kami mengundang Bapak/Ibu/Saudara/i {{{guest_name}}} untuk dapat menghadiri acara Resepsi Pernikahan kami yang akan dilaksanakan pada hari Sabtu, 10 Desember 2022.

Besar harapan kami agar Anda dapat hadir dan memberikan doa restu kepada kami. Tiada yang dapat kami ungkapkan selain rasa terima kasih yang tulus.

Untuk informasi Detail Acara, Lokasi, dan Waktu, silakan akses link undangan berikut :

https://elinvi.brawn.id/syahid-faradila/?mengundang={{{encoded_guest_name}}}

Kami yang berbahagia,

Faradila Marsya & Syahid Hurriyatna`,
  "aditya-ardita": `_Bismillahirrahmanirrahim._
_Assalamu'alaikum Warahmatullahi Wabarakatuh_

Dengan hormat, perkenankan kami mengundang Bapak/Ibu/Saudara/Saudari *{{{guest_name}}}* untuk menghadiri acara pernikahan putri kami :

*Ardita Permata Alfiani binti Widiyanto K dengan Aditya Ika Nugraha bin Bunyamin*

Berikut ini tautan (link) untuk informasi lengkap tentang acara kami :

https://elinvi.brawn.id/aditya-ardita/?mengundang={{{encoded_guest_name}}}

Merupakan suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/Saudari berkenan untuk hadir dan memberikan doa restu.
Terima Kasih.

_Wassalamu'alaikum Warahmatullahi Wabarakatuh_

Hormat Kami,
*Widiyanto Kabul & Endang Pastorini Ary. M*`,
  "wawan-della":`Assalamu'alaikum Warahmatullahi Wabarokatuh.

Izinkan kami mengundang Bapak/Ibu/Saudara/i *{{{guest_name}}}* untuk dapat menghadiri acara Resepsi Pernikahan kami yang akan dilaksanakan pada hari Ahad, 29 Januari 2023.

Besar harapan kami agar Anda dapat hadir dan memberikan doa restu kepada kami. Tiada yang dapat kami ungkapkan selain rasa terima kasih yang tulus.

Untuk informasi Detail Acara, Lokasi, dan Waktu, silakan akses link undangan berikut :

https://elinvi.brawn.id/wawan-della/?mengundang={{{encoded_guest_name}}}

Kami yang berbahagia,

*Della & Wawan*`,
  "badi-mila":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Izinkan kami mengundang Bapak/Ibu/Saudara/i *{{{guest_name}}}* untuk dapat menghadiri acara Resepsi Pernikahan kami yang akan dilaksanakan pada hari Rabu, 28 Desember 2022.

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu kepada kami. Tiada yang dapat kami ungkapkan selain rasa terima kasih yang tulus.

Berikut ini tautan (link) untuk informasi lengkap acara kami :

https://elinvi.brawn.id/badi-mila/?mengundang={{{encoded_guest_name}}}

Terima Kasih.

_Wassalamu'alaikum Warahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Mila Faladiana & Nur Ngibadi*`,
  "undangan-khitan-raffi":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Izinkan kami mengundang Bapak/Ibu/Saudara/i *{{{guest_name}}}* untuk dapat menghadiri acara Walimatul Khitan putra kami, *Muhammad Raffi Attalah*, yang akan dilaksanakan pada hari Ahad, 18 Desember 2022.

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir memberikan doa untuk putra kami. 

Untuk Informasi Detail Acara, Lokasi, dan Waktu acara, silakan akses link undangan berikut :

Link acara: https://elinvi.brawn.id/undangan-khitan-raffi/?mengundang={{{encoded_guest_name}}}

Terima kasih.

_Wassalamualaikum Warahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Bapak Ade Ahmad & Ibu Racmita Musyarofah*`,
  "rizal-diah":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Izinkan kami mengundang Bapak/Ibu/Saudara/i *{{{guest_name}}}* untuk dapat menghadiri acara pernikahan kami, yang akan dilaksanakan pada hari Ahad, 18 Desember 2022.

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/rizal-diah/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Mohamad Rizal & Diah Pithaloka*`,
  "hillian-luluk":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Izinkan kami mengundang Bapak/Ibu/Saudara/i *{{{guest_name}}}* untuk dapat menghadiri acara pernikahan kami, yang akan dilaksanakan pada hari Minggu, 1 Januari 2023.

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/hillian-luluk/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Hillian Dayu Alvin & Luluk Hendra Devi Lestari Putri*`,
  "khitan-luthfie":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Izinkan kami mengundang Bapak/Ibu/Saudara/i *{{{guest_name}}}* untuk dapat menghadiri acara Walimatul Khitan putra kami, *Luthfie Sakhi Zaidan*, yang akan dilaksanakan pada hari Sabtu, 24 Desember 2022.

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/khitan-luthfie/?mengundang={{{encoded_guest_name}}}

Besar harapan kami, agar Bapak/Ibu/Saudara/i dapat hadir memberikan doa untuk putra kami. Terima kasih.

_Wassalamualaikum Warahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Bapak Sulaeman & Ibu Siti Holilah*`,
  "reva-niki":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara resepsi pernikahan kami pada:

Hari/ Tanggal: Sabtu, 7 Januari 2023
Resepsi: 10.00- 16.00
Tempat: Gedung taman pendidikan Alquran, Batu Panjang, Sumatera Barat

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/reva-niki/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Nici Trisko & Siti Syariffah Zahra*`,
  "khitan-cawan-cakra":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara Walimatul Khitan kedua putra kami, pada:

Hari/ Tanggal: Sabtu, 24 Desember 2022
Pukul: 10.00- 16.00
Tempat: Mesjid Nurul Hasanah, Pancoran Mas, Depok

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/khitan-cawan-cakra/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa untuk putra-putra kami. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Bapak Hariansyah & Ibu Ari*`,
"aliya-ario":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara resepsi pernikahan putra-putri kami, pada:

Hari/ Tanggal: Ahad, 15 Januari 2023
Pukul: 13.00 - 15.00
Tempat: Grand Ballroom Hotel Prima Cirebon

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/aliya-ario/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Bapak Achmad Toto Risdianto & Ibu Neneng Nurhayati*`,
 "alisa-mahpudin":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara resepsi pernikahan kami, pada tanggal 14 dan 15 Januari 2023.

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/alisa-mahpudin/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Alisa Septiyana & Mahpudin Hamdan*`,
"rike-rafi":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara akad dan resepsi pernikahan kami, pada:

Hari/Tanggal: Sabtu, 11 Februari 2023
Waktu Akad: 10.00 - 11.30
Waktu Resepsi: 12.00 - 15.00
Tempat: Hutan Jati Cafe & Gelato, Purwakarta

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/rike-rafi/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Rike Hardianti Pratiwi & Rafi Khaistan Isyala*`,
"ana-dede":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara resepsi pernikahan kami, pada:

Hari/ Tanggal: Minggu, 5 Februari 2023
Pukul: 09.00 - selesai
Tempat: Kp. Mulyasari, Kab. Purwakarta

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/ana-dede/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Ana Nurjanah & Dede Farid Ridwanulloh*`,
"anisa-anfiqu":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara akad dan resepsi pernikahan kami, pada:

Hari/ Tanggal: Selasa, 24 Januari 2023
Waktu Akad: 08.00 - selesai
Waktu Resepsi: 09.00 - selesai
Tempat: Dusun Kadupugur, Kab. Sumedang

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/anisa-anfiqu/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Muhammad Anfiqu Fiddin & Anisa Pratiwi*`,
"iyan-fahli":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara akad dan tasyakuran pernikahan kami, pada:

Hari/ Tanggal: Sabtu, 21 Januari 2023
Waktu Akad: 09.00 - selesai
Waktu Tasyakuran: 13.00 - selesai
Tempat: Desa Purbadana, Kab. Banyumas

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/iyan-fahli/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Mohamad Fahli & Rizki Safitri Noviyanti*`,
  "undangan-khitan-irsyad":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara Walimatul Khitan putra kami, *Muhammad Irsyadul Anam*, yang akan dilaksanakan pada::

Hari/ Tanggal: Minggu, 5 Februari 2023
Pukul: 09.00 - Selesai
Tempat: Bojong Renged, Kampung Sawah RT/RW 18/06, Kec. Teluknaga, Kab. Tangerang Banten

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/undangan-khitan-irsyad/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Bapak Muhammad Ruslan & Ibu Fitri Paridah*`,
"siska-faiz":`_Assalāmu'alaikum Warahmatullāhi Wabarakātuh._

_Bismillāhirrahmānirrahīm_

Yth Bapak/Ibu/Saudara/i *{{{guest_name}}}*

Segala puji kami haturkan kepada Allah Subhānahu Wata'ālā yang telah melimpahkan kebahagiaan kepada kami. Karena keterbatasan jarak dan waktu, dengan ini kami bermaksud mengundang bapak/ibu/saudara/i untuk menghadiri acara pernikahan kami :

*[Siska & Faiz]*

Yang insya Allah akan dilaksanakan pada hari *Sabtu, 04 Februari 2023*.

Berikut kami lampirkan link untuk detail acaranya: 

https://elinvi.brawn.id/siska-faiz/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalāmu'alaikum Warahmatullāhi Wabarakātuh_

*Siska & Faiz*`,
"syifa-achmad":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara Akad dan Resepsi pernikahan kami, pada:

*AKAD*
Hari/ Tanggal: Minggu, 5 Maret 2023
Waktu: 08.00 - 10.00
Tempat: Aula Pondok tahfidz yanbu'ul Qur'an

*RESEPSI 1*
Hari/ Tanggal: Minggu, 5 Maret 2023
Waktu: 13.00 - selesai
Tempat: Mushola Miftahul Jannah, Kec. Dawe, Kab. Kudus

*RESEPSI 2*
Hari/ Tanggal: Rabu, 8 Maret 2023
Waktu: 10.00 - selesai
Tempat: Payaman, Secang, Magelang

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/syifa-achmad/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Achmad Widodo & Syifaun Nadhifah*`,
  "undangan-cleansheet-store":`_*INVITATION OPENING CLEANSHEET STORE*_

Yth Teman/Sahabat/saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara Opening Cleansheet Store, pada:

Hari/ Tanggal: Jumat, 3 Februari 2023
Waktu : 19.00 - selesai
Tempat: Cleansheet Store

Berikut ini tautan (link) detail acara:

https://elinvi.brawn.id/undangan-cleansheet-store/?mengundang={{{encoded_guest_name}}}

Demikian invitation ini kami sampaikan,
_Let’s go to the event !!_


*Cleansheet Store*`,
  "anti-emu":`_Assalamu'alaikum Warahmatullahi Wabarokatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara Akad dan Resepsi pernikahan kami, pada:

Hari/ Tanggal: Sabtu, 18 Februari 2023
Waktu Akad: 09.00 - selesai
Waktu Resepsi: 10.00 - selesai
Tempat: Balai Desa Bandar Negeri, Lampung Timur

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/anti-emu/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Aning Desiana Rusdianti & Muhammad Satiri*`,
  "dewi-giusti":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara Akad dan Resepsi pernikahan kami, pada:

Hari/ Tanggal: Rabu, 15 Februari 2023
Waktu Akad: 07.00 - selesai
Waktu Resepsi: 19.00 - selesai
Tempat: Jl. Pacar Kembang 3 No. 5, Surabaya

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/dewi-giusti/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Dewi Kartika Sari & Giusti Vida Pristian*`,
  "intan-aiman":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara pernikahan kami, pada:

Hari/ Tanggal: Selasa, 21 Maret 2023
Waktu: 11.00 - selesai
Tempat: Desa Pacul, Kec. Talang, Kab. Tegal

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/intan-aiman/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Intan Gayatrie & Aiman Mahfuzh*`,
  "mutiara-setyo":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara pernikahan kami, pada:

Hari/ Tanggal: Sabtu, 18 Maret 2023
Waktu Akad: 08.00 - selesai
Waktu Resepsi: 09.00 - selesai
Tempat: PT. Sweet Indo Lampung Housing Lama, Lampung

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/mutiara-setyo/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Mutiara Kurnia Wati & Setyo Widodo*`,
  "galih-della":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara Akad dan Resepsi pernikahan kami, pada:

*AKAD*
Hari/ Tanggal: Minggu, 5 Maret 2023
Waktu: 09.00 - selesai
Tempat: Kp. Walini, Rancaekek, Kab. Bandung

*RESEPSI*
Hari/ Tanggal: Kamis, 9 Maret 2023
Waktu: 11.00 - 17.00
Tempat: Jl. Siliwangi Gang Sekhmagelung V no 2, Cirebon

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/galih-della/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Muhamad Galih Sumaji & Della Mitha Nurhani*`,
  "nafis-syukron":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi pernikahan kami, pada:

Hari/ Tanggal: Senin, 13 Maret 2023
Waktu : 14.00 - selesai
Tempat: Jl. Kaliurang RT 001/ RW 006, Dusun Wonokasihan, Desa Gayam, Kec. Gurah, Kab. Kediri

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/nafis-syukron/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Nafisatul Husniah & Moh. Syukron*`,
  "40harian-chandra":`_Assalamu'alaikum Warahmatullahi Wabarokatuh_

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara *Mengenang 40 hari* wafatnya suami kami tercinta *Alm. Chandra Kurniawan*, yang akan dilaksanakan pada:

Hari/ Tanggal: Sabtu, 25 Maret 2023
Waktu: 16.00 - selesai
Tempat: Lorong Gaya Baru No.7 RT 01/RW 01, Plaju, Kota Palembang

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/40harian-chandra/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa untuk Almarhum. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berduka,

*Keluarga Alm. Chandra Kurniawan*`,
  "lena-agung":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara Akad dan Resepsi pernikahan kami, pada:

*AKAD*
Hari/ Tanggal: Selasa, 14 Maret 2023
Waktu: 09.00 - selesai
Tempat: Jl. Matseh Matyasin RT 6/RW 2, Ds. Samba Bakumpai, Kab. Katingan, Kalimantan Tengah

*RESEPSI*
Hari/ Tanggal: Selasa, 14 Maret 2023
Waktu: 10.00 - selesai
Tempat: Jl. Matseh Matyasin RT 6/RW 2, Ds. Samba Bakumpai, Kab. Katingan, Kalimantan Tengah

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/lena-agung/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Nor Lena & Slamet Agung Sugiarto*`,
  "in-memoriam-of-edward":
`Yth Bapak/Ibu/Saudara/i
{{{guest_name}}}

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk menghadiri Bidston syukur mengenang 1 tahun wafatnya anak kami tercinta *Edward Timotius Jonathan*, yang diadakan pada:

Hari/ Tanggal: Sabtu, 8 April 2023
Pukul: 18.00 WIB
Tempat: Asmarandana Ballroom, Grand Cordela Hotel, Kuningan

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/in-memoriam-of-edward/?mengundang={{{encoded_guest_name}}}

Kehadiran Bapak/Ibu/Saudara/i adalah 
bagian dari doa dan penghiburan bagi kami sekeluarga.

Teriring Salam dan Doa,

*Kel. Eddy Mulyadi*`,
  "nida-hendro":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara Akad dan Resepsi pernikahan kami, pada:

*AKAD*
Hari/ Tanggal: Jumat, 7 Juli 2023
Waktu: 09.00 - selesai
Tempat: Jl. Zaenal Abidin Rt. 06 Rw. 01, Ds. Kademangaran, Kec. Dukuhturi, Kab. Tegal

*SYUKURAN*
Hari/ Tanggal: Sabtu, 8 Juli 2023
Waktu: 13.00 - selesai
Tempat: Jl. Zaenal Abidin Rt. 06 Rw. 01, Ds. Kademangaran, Kec. Dukuhturi, Kab. Tegal

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/nida-hendro/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Nida Mumtazatul Rizqi & Hendronoto*`,
  "desti-hilman":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara pernikahan

*Desti Sulyawati*
Putri dari Bapak Tahya dan Ibu Dini Ningrat
&
*Hilman Sanjaya*
Putra dari Bapak Andi Warully dan Ibu Rika Susanti

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/desti-hilman/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Desti & Hilman*`,
  "balerina-agus":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara pernikahan

*Balerina Putri Samegtina, S.Pd*
Putri dari Bapak Samegto Yuwono dan Ibu Sunarti
&
*Agus Widodo, S.S*
Putra dari Kamin (Alm) dan Ibu Jayem

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/balerina-agus/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Balerina & Agus*`,
  "santus-jelin": `
Kepada Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara pernikahan

*Chrisantus Range Gheda Anandita, ST*
Putra pertama dari Bapak Donatus Dita Mete, SH, M.Hum & Ibu Dra. Yohana Ra Mete
dengan
*Matilda Angelina Inna, S.Pd*
Putri pertama dari Bapak Fransiskus Malo Ngongo (Alm) & Ibu Dominika Danga Bili, S.Pd

Berikut ini tautan (link) untuk informasi lengkap acara:
Link acara: https://elinvi.brawn.id/santus-jelin/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

Kami yang berbahagia,
Santus & Jelin`,
  "yuli-andri":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara pernikahan

*Yuliana Dewi*
Putri dari Bapak Kardian dan Ibu Ika
&
*Muhamad Andri Sabana*
Putra dari Bapak Amad Saefudin dan Ibu Jubaedah

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/yuli-andri/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Yuli & Andri*`,
  "eneng-ikhsan":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara pernikahan

*Eneng Rita Angraeni*
Putri dari Bapak Ucep (Alm) dan Ibu Ratini
&
*Ikhsan Abdul Malik*
Putra dari Bapak Didik Iskandar (Alm) dan Ibu Sugiyanti

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/eneng-ikhsan/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Eneng & Ikhsan*`,
  "anna-diwanqi":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara pernikahan

*Anna Afifah*
Putri dari Bapak Usep Ade Indrayana dan Ibu Sri Lestari
&
*Diwanqi Rohmani*
Putra dari Bapak Entoh dan Ibu Komala

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/anna-diwanqi/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Anna & Diwanqi*`,
  "dila-aji":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara pernikahan

*Nuradilah*
Putri dari Bapak Aman Sutarman dan Ibu Siti Atisah
&
*Aji Prasetia*
Putra dari Bapak Jakaria (Alm) dan Ibu Neneng Atikah

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/dila-aji/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Dila & Aji*`,
  "yani-holik":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara pernikahan

*Siti Nuryani*
Putri bungsu dari Bapak Ojen dan Ibu Enok
&
*Abdul Holik*
Putra pertama dari Bapak Emuk dan Ibu Elih

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.brawn.id/yani-holik/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Yani & Holik*`,
  "syarif-vina":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Syarif Pratama*
Putra dari Bapak Jumali dan Ibu Sahirah
&
*Alvin Nasuha*
Putri dari Bapak Samsudin & Ibu Nasuha

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/syarif-vina/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Syarif & Vina*`,
  "tantri-hasan":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._
  _Bismillahirrahmanirrahim_

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, perkenankan kami mengundang Bapak/Ibu/Saudara/i, teman sekaligus sahabat, untuk menghadiri acara RESEPSI kami:

TANTRI NURPADILAH & HASANUDIN

Hari/Tanggal: Rabu, 18 Oktober 2023
Waktu: 10.00 - selesai
Tempat: Kp. Babakan RT 001/RW 003, Desa Gembongan, Kec. Banyusari, Kab. Karawang

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/tantri-hasan/?mengundang={{{encoded_guest_name}}}

Merupakan suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu.
Atas perhatiannya kami ucapkan terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Tantri & Hasan*`,
  "ridha-eko":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Ridha Fadillah*
Putri dari Bapak Kuswanto dan Ibu Kawen
&
*Eko Priyono*
Putra dari Bapak Rasmun & Ibu Sikem

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/ridha-eko/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Ridha & Eko*`,
  "fise-eka":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Fise Rahmawati, M.Pd*
Putri dari Bapak Drs. H. Bahrun Jamil dan Ibu Hj. Ike Yulike
&
*Eka Natakusuma, S.Pd*
Putra dari Bapak Pakih & Ibu Titi

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/fise-eka/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Fise & Eka*`,
  "nur-man":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Nurdiana*
Putri dari Bapak Wastam dan Ibu Lilis
&
*Rohman Dani*
Putra dari Bapak Yadi (Alm) & Ibu Nanih

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/nur-man/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Nurdiana & Rohman*

_Gunakan dan Save filter IG berikut untuk mengabadikan momen bahagia di acara pernikahan kami._
https://www.instagram.com/ar/773300061476058`,
  "latif-rudy":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Latif Gunanti*
Putri dari Bapak Dani dan Ibu Tugiyarti
&
*Rudy Dwi Saputro*
Putra dari Bapak Subiyanto & Ibu Tutik

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/latif-rudy/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Latif & Rudy*`,
  "nisa-riky":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Khairun Nisa*
Putri dari Bapak M. Masnin dan Ibu Aisyah
&
*Riky Winanda*
Putra dari Bapak Arisman & Ibu Ati

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/nisa-riky/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Nisa & Riky*


_Gunakan dan Save filter IG berikut untuk mengabadikan momen bahagia di acara pernikahan kami._
https://www.instagram.com/ar/237012665796269/`,
  "ela-nopal":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Nur Ela*
Putri dari Bapak Jumsari dan Ibu Enih Nuraeni
&
*Nopal Maulana Ishaq*
Putra dari Bapak Abdul Wahab & Ibu Nurhasanah

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/ela-nopal/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Ela & Nopal*`,
  "eka-syaiful":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Eka Fitri Rahmawati*
Putri dari Bapak Narjo (alm) dan Ibu Sumiyati
&
*Syaiful Ma'sum*
Putra dari Bapak Juwadi (alm) dan Ibu Suyatmi (almh)

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/eka-syaiful/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Eka & Syaiful*`,
  "lisa-ari":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Lisa Safitriyani*
Putri dari Bapak Erwin dan Ibu Eutik
&
*Ari Pratama Nugraha*
Putra dari Bapak Atang (alm) dan Ibu Eros

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/lisa-ari/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Lisa & Ari*`,
  "putri-wahyu":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Putri Utami*
Putri dari Bapak Dwian Prastawa dan Ibu Miyati
&
*Wahyu Susilo Alfiantoro*
Putra dari Bapak Suroso dan Ibu Warsiyati (alm)

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/putri-wahyu/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Putri & Wahyu*`,
  "undangan-raker-bfi": 
`Kepada Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Dengan segala rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri acara Rapat Kerja BFI tahun 2024 yang akan dilaksanakan pada 

Hari: Selasa, 13 Februari 2024
Waktu: 08.15 WIB - selesai
Lokasi: BFI Tower, Lantai 5, Ruang Continuous Improvement

Berikut ini tautan (link) untuk informasi lengkap acara:
https://https://elinvi.com/undangan-raker-bfi/?mengundang={{{encoded_guest_name}}}

Hormat Kami,
*BFI*`,
  "dian-ilham":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Dian Rizky Amelia*
Putri dari Bapak Hasan Basri dan Ibu Dewi Purnamasari
&
*Ilham Arrasyid Hudaya*
Putra dari Bapak Asep Hudaya dan Ibu Yulianingsih

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/dian-ilham/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Dian & Ilham*`,
  "widiya-sandi":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Widiya Ari Lestari*
Putri dari Bapak Ngadimin dan Ibu Sari
&
*Sandi Julia Putra*
Putra dari Bapak Setiawan dan Ibu Parsiyem

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/widiya-sandi/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Widiya & Sandi*`,
  "eka-arip":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Eka Khoerotunnisa, S.Pd.*
Putri dari Bapak Muslihin dan Ibu Lilis Nuryani
&
*Arip Hidayat, S.E.*
Putra dari Bapak Iin Abdurohman dan Ibu Ade Mimin Aminah

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/eka-arip/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Eka & Arip*`,
  "sarah-dezan":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Sarah Islamiati*
Putri dari Bapak Sulaeman dan Ibu Kokom Rohayati
&
*Dezan Nurawaludin*
Putra dari Bapak Sulaeman dan Ibu Latipah

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/sarah-dezan/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Sarah & Dezan*`,
    "nena-fikry":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Janaenah, S.Pd. M.M.*
Putri dari Bapak Yayat
&
*Achmad Fikry, S. IK*
Putra dari Bapak H. Achmad Hatomi (Alm.)

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/nena-fikry/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Nena & Fikry*`,
  "fitri-dena":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Siti Fitriani Fatimah, S.S.*
Putri dari Bapak Sanip & Ibu Martini
&
*Dena Ahmad Maulana*
Putra dari Bapak Tohir & Ibu Aah Rosnia

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/fitri-dena/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Fitri & Dena*`,
  "winda-agus":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Winda Ardila*
Putri dari Bapak Rudi Purwanto dan Ibu Surtiyah
&
*Agus Rianto*
Putra dari Bapak Darsiman dan Ibu Wasinem

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/winda-agus/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Winda & Agus*`,
  "dina-hadi":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Dina Sahnie Pratiwi*
Putri dari Bapak Didin Sahidin, S. Sos. & Ibu Erick Festiani, S.Sos., M.M.
&
*Mubdi Hadi Mujayid, S.T*
Putra dari Bapak Yusup Hilmi, S.Pd. & Ibu Euis Nunung Rukmini

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/dina-hadi/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Dina & Hadi*`,
  "neng-firman":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Siti Rodiah*
Putri dari Bapak Maman (Alm) & Ibu Aas
&
*Maman Abdurrohman*
Putra dari Bapak H. Syamsu (Alm) & Ibu Neng Yeti

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/neng-firman/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Neng & Firman*`,
  "nadia-aemil":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Nadia Rizki Sofari*
Putri dari Bapak H. Kiki & Ibu Hj. Yeyen
&
*Aemil Ikhlasan*
Putra dari Bapak Cucu Nata Atmaja (Alm.) & Ibu Cicih Suarsih

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/nadia-aemil/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Nadia & Aemil*`,
  "aeni-rival":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Nur Aeni*
Putri dari Bapak Mujib & Ibu Saodah
&
*Mochammad Rival Fauzi*
Putra dari Bapak Ust. Enday Hidayat & Ibu Yayan Suryani

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/aeni-rival/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Aeni & Rival*`,
  "ultah-jasmine": `Assalamu'alaikum Wr. Wb. 

Alhamdulillah sudah tiba saatnya Ulang Tahun Jasmine.. 

Undangan ini spesial teruntuk:
  
*{{{guest_name}}}*

Besar harapan kami untuk bisa menghadiri Ulang Tahun Jasmine yang ke-6, yang akan dilaksanakan pada Hari Minggu, 24 November 2024.

Berikut ini tautan (link) untuk informasi lengkap acara:

Link acara: https://elinvi.com/ultah-jasmine/?mengundang={{{encoded_guest_name}}}

Semoga kita semua dalam keadaan sehat dan bahagia selalu aamiin YRA 

Sampai Jumpa.. 😍 
 
 Wassalam
- Jasmine-

*CATATAN : 
Mohon dilengkapi Form Reservasi di Undangan 🙏🙏🤗🤗`,
  "nengyuli-adi":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Yulia*
Putri dari Bapak Mamat Enip & Ibu Alis
&
*Adi Krisyiantara*
Putra dari Bapak Wahyudin & Ibu Lilis Kartini

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/nengyuli-adi/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Yulia & Adi*`,
  "khitan-lhatief-fhatan": `_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri menghadiri acara Walimatul Khitan 

*Lhatief Akmal Elhasan*
&
*Fhatan Alfarizi Nahendra*

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/khitan-lhatief-fhatan/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,
Bapak Hendra dan Ibu Widya`,
    "fita-ade":`_Assalamu'alaikum Warahmatullahi Wabarakatuh._

Yth Bapak/Ibu/Saudara/i
*{{{guest_name}}}*

Tanpa mengurangi rasa hormat, izinkan kami mengundang Bapak/Ibu/Saudara/i untuk dapat menghadiri Resepsi Pernikahan

*Fita Riyani*
Putri dari Bapak Sansuwarto & Ibu Watinem
&
*Ade Nurhuda*
Putra dari Bapak Mahjudi & Ibu Makhini

Berikut ini tautan (link) untuk informasi lengkap acara:

https://elinvi.com/fita-ade/?mengundang={{{encoded_guest_name}}}

Besar harapan kami agar Bapak/Ibu/Saudara/i dapat hadir dan memberikan doa restu. Terima kasih.

_Wassalamualaikum Warrahmatullahi Wabarakatuh_

Kami yang berbahagia,

*Fita & Ade*`,
  
}
